

    
export type EmployeeStatus = 'Available' | 'Away';

export const EmployeeStatus = {
    Available: 'Available' as EmployeeStatus,
    Away: 'Away' as EmployeeStatus
};

